<style scoped>
.v-data-table__wrapper th:nth-child(4),
.v-data-table__wrapper td:nth-child(4) {
  min-width: 240px;
}

.header {
  color: white;
  font-size: 14px;
  word-break: break-all;
}

.v-data-table__wrapper th:nth-child(2),
.v-data-table__wrapper td:nth-child(2) {
  max-width: 200px;
}

.dialog-table td:first-child {
  width: 180px;
  font-weight: 700 !important;
}

.dialog-campaign-table {
  margin-left: 8px;
}

.v-text-field--disabled .v-input__control {
  background-color: rgba(0, 0, 0, 0.38);
}

.v-card-title {
  font-size: 18px;
  font-weight: bold;
}

.v-card-text p {
  font-size: 16px;
  margin-bottom: 20px;
}

.v-btn {
  margin-right: 10px;
}
</style>

<template>
  <div class="ma-10">
    <v-card width="60vw">
      <v-toolbar color="#1b2643" flat>
        <v-toolbar-title class="header">
          {{ $t('editPackage.header') }}
        </v-toolbar-title>
      </v-toolbar>

      <v-container class="d-flex flex-column pa-5" style="gap: 40px">
        <div style="gap: 10px">
          <v-btn
            color="#1b2643"
            class="white--text btn_shadow_half my-5"
            style="width: fit-content"
            @click="$router.back()"
          >
            {{ $t('common.back') }}
          </v-btn>
          <p class="text-h6">{{ $t('newPackage.subHeader') }}</p>

          <v-text-field
            v-model="packageCode"
            :label="$t('editPackage.packageCode')"
            disabled
            outlined
            persistent-placeholder
          ></v-text-field>

          <v-text-field
            v-model="packageName"
            :label="$t('newPackage.packageName')"
            :rules="[rules.required]"
            outlined
            persistent-placeholder
          ></v-text-field>

          <p class="text-body-2" v-html="$t('newPackage.coupon.text')"></p>

          <v-row>
            <v-col cols="7">
              <v-file-input
                v-model="csvFile"
                :error-messages="CSVInputErrorMessage"
                :label="$t('newPackage.coupon.couponFormat')"
                accept="text/csv"
                outlined
                persistent-placeholder
                @change="validateCSVInputFile"
              ></v-file-input>
            </v-col>

            <v-col class="mt-2" cols="5">
              <v-btn class="btn_shadow_half" @click="downloadCsvSample">
                <v-icon small>mdi-download</v-icon>
                {{ $t('newPackage.coupon.buttonText') }}
              </v-btn>
            </v-col>
          </v-row>

          <p class="text-body1">{{ $t('newPackage.viewingPeriod.text') }}</p>

          <div class="d-flex" style="gap: 20px">
            <v-card outlined>
              <v-card-text class="text-caption pb-0">
                <span style="background-color: white">
                  {{ $t('newPackage.viewingPeriod.startAt') }}
                </span>
              </v-card-text>
              <v-container class="pb-6">
                <v-container class="d-flex" style="gap: 20px">
                  <DateTimePicker
                    :date="startViewingDate"
                    :dateLabel="dateLabel"
                    :error-messages="viewingPeriodErrorMessage"
                    :time="startViewingTime"
                    :timeLabel="timeLabel"
                    :timeOptions="availableTimeSlots"
                    icon="mdi-calendar"
                    @update:date="startViewingDate = $event"
                    @update:time="startViewingTime = $event"
                    @validate-period="validateViewingPeriod"
                  />
                </v-container>
              </v-container>
            </v-card>

            <v-card outlined>
              <v-card-text class="text-caption pb-0">
                <span style="background-color: white">
                  {{ $t('newPackage.viewingPeriod.endAt') }}
                </span>
              </v-card-text>
              <v-container class="pb-6">
                <v-container class="d-flex" style="gap: 20px">
                  <DateTimePicker
                    :date="endViewingDate"
                    :dateLabel="dateLabel"
                    :error-messages="viewingPeriodErrorMessage"
                    :time="endViewingTime"
                    :timeLabel="timeLabel"
                    :timeOptions="availableTimeSlots"
                    icon="mdi-calendar"
                    @update:date="endViewingDate = $event"
                    @update:time="endViewingTime = $event"
                    @validate-period="validateViewingPeriod"
                  />
                </v-container>
                <span class="error--text text-caption">
                  {{ viewingPeriodErrorMessage }}
                </span>
              </v-container>
            </v-card>
          </div>

          <div class="pt-5">
            <p class="text-h6">{{ $t('newPackage.campaign.text') }}</p>

            <v-simple-table class="pb-5">
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('newPackage.campaign.header[0]') }}
                    </th>
                    <th class="text-left">
                      {{ $t('newPackage.campaign.header[1]') }}
                    </th>
                    <th class="text-left">
                      {{ $t('newPackage.campaign.header[2]') }}
                    </th>
                    <th class="text-left">
                      {{ $t('newPackage.campaign.header[3]') }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(campaign, index) in editableCampaigns"
                    :key="index"
                  >
                    <td>
                      <v-text-field
                        v-model="campaign.name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-model="campaign.scenarioDocId"
                        :items="availableScenarios"
                        item-text="scenarioName"
                        item-value="scenarioDocId"
                      ></v-select>
                    </td>
                    <td>
                      <v-container class="text-center">1</v-container>
                    </td>
                    <td>
                      <v-container class="d-flex pa-0">
                        <DateTimePicker
                          :date="campaign.startInterviewDate"
                          :error-messages="campaign.errorMessage"
                          :time="campaign.startInterviewTime"
                          :timeOptions="availableTimeSlots"
                          @update:date="campaign.startInterviewDate = $event"
                          @update:time="campaign.startInterviewTime = $event"
                          @validate-period="validateViewingPeriod(campaign)"
                        />
                      </v-container>

                      <v-container
                        class="d-flex pa-0 align-baseline"
                        style="gap: 10px"
                      >
                        <v-icon small class="pa-0">mdi-tilde</v-icon>
                        <v-container class="pr-0">
                          <DateTimePicker
                            :date="campaign.endInterviewDate"
                            :error-messages="campaign.errorMessage"
                            :time="campaign.endInterviewTime"
                            :timeOptions="availableTimeSlots"
                            @update:date="campaign.endInterviewDate = $event"
                            @update:time="campaign.endInterviewTime = $event"
                            @validate-period="validateViewingPeriod(campaign)"
                          />
                        </v-container>
                      </v-container>
                      <span class="error--text text-caption">
                        {{ campaign.errorMessage }}
                      </span>
                    </td>
                    <td>
                      <v-container class="d-flex">
                        <v-btn
                          :disabled="index === 0"
                          icon
                          small
                          @click="moveUp(index)"
                        >
                          <v-icon small>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                          :disabled="index === editableCampaigns.length - 1"
                          icon
                          small
                          @click="moveDown(index)"
                        >
                          <v-icon small>mdi-arrow-down</v-icon>
                        </v-btn>
                        <v-btn
                          :disabled="editableCampaigns.length === 1"
                          icon
                          small
                          @click="deleteCampaign(index)"
                        >
                          <v-icon small>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-container>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-btn
              elevation="0"
              small
              style="width: 100%"
              @click="createNewCampaign"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="d-flex justify-center pa-8">
          <v-dialog persistent v-model="editDialog" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!isFormModified || isInputError"
                class="white--text mr-3"
                color="#1b2643"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('editPackage.edit') }}
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="#1b2643" dark>
                  {{ $t('editPackage.editDialogHeader') }}
                </v-toolbar>

                <v-card-text
                  style="padding: 0; overflow-x: scroll; max-height: 500px"
                >
                  <v-expansion-panels :value="[0, 1, 2]" accordion multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="text-h6">
                          {{ $t('editPackage.packageSettings') }}
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <table class="dialog-table">
                          <tr>
                            <td>{{ $t('editPackage.packageCodeLabel') }}</td>
                            <td>{{ packageCode }}</td>
                          </tr>

                          <tr>
                            <td>{{ $t('editPackage.packageNameLabel') }}</td>
                            <td>
                              <span
                                :style="{
                                  color: modifications.packageName
                                    ? 'red'
                                    : 'inherit',
                                }"
                              >
                                {{ packageName }}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td>{{ $t('editPackage.viewingPeriodLabel') }}</td>
                            <td>
                              <span
                                :style="{
                                  color: isViewingPeriodFieldModified()
                                    ? 'red'
                                    : 'inherit',
                                }"
                              >
                                {{ viewingPeriodDateTime }}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="text-h6">
                          {{
                            $t('editPackage.campaigns.title', {
                              count: editableCampaigns.length,
                            })
                          }}
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <div class="d-flex flex-column" style="gap: 20px">
                          <div
                            v-for="(campaign, index) in editableCampaigns"
                            :key="index"
                          >
                            <p class="text-body-1 font-weight-bold">
                              <u>{{
                                $t('editPackage.campaigns.index', {
                                  index: index + 1,
                                })
                              }}</u>
                            </p>
                            <table class="dialog-table dialog-campaign-table">
                              <tr>
                                <td>{{ $t('editPackage.campaigns.name') }}</td>
                                <td>
                                  <span
                                    :style="{
                                      color: isCampaignFieldModified(
                                        index,
                                        'name'
                                      )
                                        ? 'red'
                                        : 'inherit',
                                    }"
                                  >
                                    {{ campaign.name }}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {{ $t('editPackage.campaigns.scenario') }}
                                </td>
                                <td>
                                  <span
                                    :style="{
                                      color: isCampaignFieldModified(
                                        index,
                                        'scenarioDocId'
                                      )
                                        ? 'red'
                                        : 'inherit',
                                    }"
                                  >
                                    {{
                                      getScenarioName(campaign.scenarioDocId)
                                    }}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  {{
                                    $t('editPackage.campaigns.interviewCount')
                                  }}
                                </td>
                                <td>1</td>
                              </tr>

                              <tr>
                                <td>
                                  {{
                                    $t('editPackage.campaigns.interviewPeriod')
                                  }}
                                </td>
                                <td>
                                  <span
                                    :style="{
                                      color: isCampaignInterviewPeriodModified(
                                        campaign
                                      )
                                        ? 'red'
                                        : 'inherit',
                                    }"
                                  >
                                    {{ interviewDateTime(campaign) }}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="text-h6">
                          {{ $t('editPackage.addNewCoupons.title') }}
                          <span style="color: red"
                            >({{ importedCoupons.length }})</span
                          >
                        </span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="couponHeaders"
                          :items="importedCoupons"
                          :footer-props="{
                            'items-per-page-options': itemsPerPageOptions,
                          }"
                          dense
                        ></v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>

                <v-card-actions class="justify-end pt-4 pb-4">
                  <v-btn text @click="dialog.value = false">
                    <v-icon left>mdi-arrow-u-left-top</v-icon>
                    {{ $t('editPackage.cancel') }}
                  </v-btn>
                  <v-btn
                    :loading="isEditing"
                    color="#1b2643"
                    dark
                    @click="handleEditSubmit"
                  >
                    <v-icon left>mdi-check</v-icon>
                    {{ $t('editPackage.edit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <v-btn
            class="white--text"
            color="#FF6160"
            @click="deletePackageConfirm"
          >
            <v-icon left>mdi-trash-can-outline</v-icon>
            {{ $t('editPackage.delete') }}
          </v-btn>
        </div>

        <!-- Delete Confirmation Modal -->
        <v-dialog v-model="deleteDialog" width="500">
          <v-card style="padding-bottom: 8px">
            <v-toolbar color="#1b2643" dark>
              {{ $t('editPackage.deleteDialogHeader') }}
            </v-toolbar>

            <v-card-text>
              <div
                class="text-center"
                style="padding-top: 20px; text-align: center"
              >
                <p style="white-space: pre-wrap">
                  {{ $t('editPackage.deleteConfirmationMessage') }}
                </p>
              </div>
            </v-card-text>

            <v-card-actions class="justify-end pt-4 pb-4">
              <v-btn text @click="deleteDialog = false">
                <v-icon left>mdi-arrow-u-left-top</v-icon>
                {{ $t('editPackage.cancel') }}
              </v-btn>
              <v-btn :loading="isDeleting" color="error" @click="confirmDelete">
                <v-icon left>mdi-trash-can-outline</v-icon>
                {{ $t('editPackage.delete') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <snack-bar
            :snackbarAction="deleteErrorSnackbar"
            :snackbarText="deleteErrorSnackbarText"
            snackbarColor="pink"
            @closeSnackbar="deleteErrorSnackbar = false"
          />
        </v-dialog>
      </v-container>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { v4 } from 'uuid'
import moment from 'moment-timezone'
import Papa from 'papaparse'
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  setDoc,
  addDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import DateTimePicker from '@/components/Dashboard/PageViewItems/DateTimePicker.vue'
import SnackBar from '@/components/Dashboard/PageViewItems/SnackbarComponent.vue'
import { db, functions } from '@/plugins/firebase'
import type { Campaign } from '@/resources/campaign'
import type { Customer } from '@/resources/customer'
import type { Package } from '@/resources/package'
import store from '@/store'
import { createIsoUtcDateTime, getJstTime } from '@/components/utils/dateUtils'

type ModifiableCampaignData = {
  id?: string
  name?: string
  scenarioDocId?: string
  startInterviewDate?: string | null
  startInterviewTime?: string
  endInterviewDate?: string | null
  endInterviewTime?: string
  errorMessage?: string
  index?: number
}

type CampaignDataChanges = Partial<Campaign> & {
  id: string | null
  index: number
  operation: 'create' | 'update' | 'delete' | 'none'
}

type Coupon = {
  couponCode: string
  password: string
  learnerId: string | null
  isConfirmed: boolean
}

type PackageCoupon = Omit<Coupon, 'isConfirmed'>

type Scenario = {
  scenarioDocId: string
  scenarioName: string
}

type AgentGroups = {
  agentGroupId: string
  agentGroupName: string
}

type ModifiableField =
  | 'packageName'
  | 'startViewingDate'
  | 'startViewingTime'
  | 'endViewingDate'
  | 'endViewingTime'

type CampaignField =
  | 'name'
  | 'scenarioDocId'
  | 'startInterviewDate'
  | 'startInterviewTime'
  | 'endInterviewDate'
  | 'endInterviewTime'
  | 'index'

interface EditPackageFormState {
  packageName: string
  startViewingDate: string
  startViewingTime: string
  endViewingDate: string
  endViewingTime: string
  campaigns: ModifiableCampaignData[]
}

interface Modifications {
  packageName: boolean
  viewingPeriod: {
    startViewingDate: boolean
    startViewingTime: boolean
    endViewingDate: boolean
    endViewingTime: boolean
  }
  campaigns: Record<string, Record<string, boolean>>
  newCampaigns: Set<number>
  campaignOrderModified: boolean
}

type CampaignData = Campaign & {
  id: string
  index: number
}

export default defineComponent({
  name: 'EditPackage',

  components: {
    DateTimePicker,
    SnackBar,
  },

  data() {
    return {
      // Dialog states
      editDialog: false,
      deleteDialog: false,
      isEditing: false,
      isDeleting: false,

      deleteErrorSnackbar: false,
      deleteErrorSnackbarText: '',

      // Table headers
      couponHeaders: [
        {
          text: this.$t('editPackage.addNewCoupons.couponCode'),
          value: 'couponCode',
          sortable: false,
        },
        {
          text: this.$t('editPackage.addNewCoupons.password'),
          value: 'password',
          sortable: false,
        },
      ],
      itemsPerPageOptions: [10, 100, -1],

      // Read-only data
      currentPackage: null as Package | null,
      availableScenarios: [] as Scenario[],
      allCampaigns: [] as CampaignData[],
      allPackageCoupons: [] as PackageCoupon[],
      availableTimeSlots: [] as string[],

      // Form state
      csvFile: null as File | null,
      importedCoupons: [] as Coupon[],

      packageCode: '' as string,
      packageName: '' as string,

      // Viewing period
      startViewingDate: null as string | null,
      startViewingTime: '' as string,
      endViewingDate: null as string | null,
      endViewingTime: '' as string,

      editableCampaigns: [] as ModifiableCampaignData[],
      campaignIdsToDelete: [] as string[],

      initialFormState: {
        packageName: '',
        startViewingDate: '',
        startViewingTime: '',
        endViewingDate: '',
        endViewingTime: '',
        campaigns: [] as ModifiableCampaignData[],
      } as EditPackageFormState,

      modifications: {
        packageName: false,
        viewingPeriod: {
          startViewingDate: false,
          startViewingTime: false,
          endViewingDate: false,
          endViewingTime: false,
        },
        campaigns: {} as Record<string, Record<string, boolean>>,
        newCampaigns: new Set<number>(),
        campaignOrderModified: false,
      } as Modifications,

      isFormModified: false,

      // Validation messages
      CSVInputErrorMessage: '' as string | null,
      viewingPeriodErrorMessage: '' as string | '',
    }
  },

  async mounted() {
    const packageId = this.$route.params.packageId as string
    const packageData = await this.fetchPackageById(packageId)
    if (!packageData) {
      this.$router.push('/dashboard/home')
      return
    }

    const campaignData = await this.fetchAllCampaigns()
    const packageCoupons = await this.fetchPackageCoupons()

    this.initializeFormData(packageData, campaignData, packageCoupons)
    await this.fetchScenarios()
  },

  watch: {
    packageName: {
      handler(newValue: string) {
        this.trackChange('packageName', newValue)
      },
      immediate: true,
    },

    startViewingDate: {
      handler(newValue: string) {
        this.trackChange('startViewingDate', newValue)
      },
      immediate: true,
    },

    startViewingTime: {
      handler(newValue: string) {
        this.trackChange('startViewingTime', newValue)
      },
      immediate: true,
    },

    endViewingDate: {
      handler(newValue: string) {
        this.trackChange('endViewingDate', newValue)
      },
      immediate: true,
    },

    endViewingTime: {
      handler(newValue: string) {
        this.trackChange('endViewingTime', newValue)
      },
      immediate: true,
    },

    editableCampaigns: {
      deep: true,
      handler(campaigns: ModifiableCampaignData[]) {
        campaigns.forEach((campaign, index) => {
          if (!campaign.id) {
            this.modifications.newCampaigns.add(index)
            return
          }

          this.trackCampaignChange(index, 'name', campaign.name ?? '')
          this.trackCampaignChange(
            index,
            'scenarioDocId',
            campaign.scenarioDocId ?? ''
          )

          const interviewFields = [
            'startInterviewDate',
            'startInterviewTime',
            'endInterviewDate',
            'endInterviewTime',
          ] as const

          interviewFields.forEach((field) => {
            this.trackCampaignChange(index, field, campaign[field] ?? '')
          })
        })
        this.isFormModified = this.updateIsFormModified()
      },
      immediate: true,
    },

    modifications: {
      deep: true,
      handler() {
        this.isFormModified = this.updateIsFormModified()
      },
      immediate: true,
    },

    importedCoupons: {
      handler() {
        this.isFormModified = this.updateIsFormModified()
      },
      immediate: true,
    },

    campaignIdsToDelete: {
      handler() {
        this.isFormModified = this.updateIsFormModified()
      },
      immediate: true,
    },
  },

  computed: {
    rules() {
      return {
        required: (value: string) =>
          !!value || this.$t('editPackage.validation.required'),
      }
    },

    isInputError(): boolean {
      if (this.packageName === '') return true
      if (this.editableCampaigns.some((campaign) => campaign.name === '')) {
        return true
      }
      if (
        this.editableCampaigns.some((campaign) => {
          this.validateViewingPeriod(campaign)
          return campaign.errorMessage !== ''
        })
      ) {
        return true
      }
      if (this.viewingPeriodErrorMessage !== '') return true
      if (this.CSVInputErrorMessage !== '') return true
      return false
    },

    dateLabel(): string {
      return this.$t('newPackage.viewingPeriod.date') as string
    },

    timeLabel(): string {
      return this.$t('newPackage.viewingPeriod.time') as string
    },

    viewingPeriodDateTime(): string {
      return this.formatDateTimeRange(
        this.startViewingDate,
        this.startViewingTime,
        this.endViewingDate,
        this.endViewingTime
      )
    },
  },

  methods: {
    // ===== Lifecycle Methods =====
    initializeFormData(
      packageData: Package,
      campaignData: CampaignData[],
      packageCoupons: PackageCoupon[]
    ) {
      this.packageCode = packageData.code
      this.packageName = packageData.name

      const openAtParsed = this.parseFirestoreDateTime(packageData.openAt)
      const closedAtParsed = this.parseFirestoreDateTime(packageData.closedAt)

      this.startViewingDate = openAtParsed.date
      this.startViewingTime = openAtParsed.time
      this.endViewingDate = closedAtParsed.date
      this.endViewingTime = closedAtParsed.time

      this.initialFormState.packageName = this.packageName
      this.initialFormState.startViewingDate = this.startViewingDate
      this.initialFormState.startViewingTime = this.startViewingTime
      this.initialFormState.endViewingDate = this.endViewingDate
      this.initialFormState.endViewingTime = this.endViewingTime

      this.allCampaigns = campaignData
      this.allPackageCoupons = packageCoupons

      const campaignIds = packageData.campaignIds
      const packageCampaigns: CampaignData[] = campaignIds
        .map((id, index) => ({
          ...this.allCampaigns.find((campaign) => campaign.id === id),
          index,
        }))
        .filter((campaign): campaign is CampaignData => campaign !== undefined)

      this.editableCampaigns = packageCampaigns.map(
        this.convertToModifiableCampaign
      )
      this.initialFormState.campaigns = JSON.parse(
        JSON.stringify(this.editableCampaigns)
      )
      this.editableCampaigns.forEach((campaign) => {
        this.validateViewingPeriod(campaign)
      })
    },

    // ===== Data Fetching Methods =====
    async fetchPackageById(packageId: string): Promise<Package | null> {
      try {
        if (!packageId) {
          throw new Error('Package ID is required but was not provided')
        }

        const packageDoc = await getDoc(doc(db, 'packages', packageId))
        if (!packageDoc.exists()) {
          console.warn(`Package with ID ${packageId} not found`)
          return null
        }

        const packageData = packageDoc.data()
        if (!packageData) {
          throw new Error(`Package data is empty for ID ${packageId}`)
        }

        // Validate required fields
        const requiredFields = [
          'code',
          'name',
          'campaignIds',
          'customerId',
          'openAt',
          'closedAt',
        ]
        const missingFields = requiredFields.filter(
          (field) => !(field in packageData)
        )
        if (missingFields.length > 0) {
          throw new Error(
            `Package is missing required fields: ${missingFields.join(', ')}`
          )
        }

        this.currentPackage = {
          id: packageId,
          code: packageData.code,
          name: packageData.name,
          couponCount: packageData.couponCount,
          campaignIds: packageData.campaignIds,
          customerId: packageData.customerId,
          customerCode: packageData.customerCode,
          openAt: packageData.openAt,
          closedAt: packageData.closedAt,
          createdAt: packageData.createdAt,
          updatedAt: packageData.updatedAt,
          deletedAt: packageData.deletedAt,
        }

        return packageData as Package
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error occurred'
        console.error(`Error getting package ${packageId}:`, errorMessage)
        throw error
      }
    },

    async fetchAllCampaigns(): Promise<CampaignData[]> {
      try {
        const campaignsRef = collection(db, 'campaigns')
        const campaignSnapshot = await getDocs(query(campaignsRef))
        return campaignSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as CampaignData[]
      } catch (error) {
        console.error('Error fetching campaigns:', error)
        throw error
      }
    },

    async fetchPackageCoupons() {
      try {
        const packageCouponDocs = await getDocs(
          collection(
            doc(collection(db, 'packages'), this.currentPackage?.id),
            'coupons'
          )
        )
        return packageCouponDocs.docs.map((doc) =>
          doc.data()
        ) as PackageCoupon[]
      } catch (error) {
        console.error('Error fetching package coupons:', error)
        throw error
      }
    },

    async fetchScenarios() {
      const agentGroups = [] as AgentGroups[]
      const agentGroupRef = collection(db, 'agentGroups')
      await getDocs(query(agentGroupRef)).then((agentGroupSnapshot) => {
        agentGroupSnapshot.forEach((doc) => {
          agentGroups.push({
            agentGroupId: doc.id,
            agentGroupName: doc.data().name,
          })
        })
      })

      const customerId = store.getters.customerId
      const customer = (
        await getDoc(doc(db, 'customers', customerId))
      ).data() as Customer

      const scenarioDocIds = Array.isArray(customer.scenarioDocIds)
        ? customer.scenarioDocIds
        : [customer.scenarioDocIds]

      const scenarioDocChunks = this.chunkArray(scenarioDocIds, 30)
      const scenariosRef = collection(db, 'scenarios')
      const scenarios: Scenario[] = []

      const scenariosQuery = scenarioDocChunks.map((docIds) =>
        getDocs(query(scenariosRef, where(documentId(), 'in', docIds)))
      )

      await Promise.all(scenariosQuery).then((results) => {
        results.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            const agentGroup = agentGroups.find(
              (agent) => agent.agentGroupId === doc.data().agentGroupId
            )
            scenarios.push({
              scenarioDocId: doc.id,
              scenarioName: `${doc.data().name} (${
                agentGroup?.agentGroupName
              })`,
            })
          })
        })
      })
      this.availableScenarios = scenarios
    },

    // ===== Campaign Management Methods =====
    createNewCampaign() {
      this.editableCampaigns.push({
        name: '',
        scenarioDocId: '',
        startInterviewDate: null,
        startInterviewTime: '',
        endInterviewDate: null,
        endInterviewTime: '',
        errorMessage: '',
        index: this.editableCampaigns.length,
      })
    },

    deleteCampaign(index: number) {
      const shouldDelete = confirm(
        this.$t('editPackage.deleteCampaignConfirmationMessage') as string
      )
      if (!shouldDelete) return

      const campaignToDelete = this.editableCampaigns[index]
      if (campaignToDelete.id) {
        this.campaignIdsToDelete.push(campaignToDelete.id)
      }

      this.editableCampaigns.splice(index, 1)
    },

    swapCampaigns(index1: number, index2: number) {
      const campaign1 = this.editableCampaigns[index1]
      const campaign2 = this.editableCampaigns[index2]

      this.$set(this.editableCampaigns, index1, campaign2)
      this.$set(this.editableCampaigns, index2, campaign1)

      this.editableCampaigns[index1].index = index1
      this.editableCampaigns[index2].index = index2

      this.modifications.campaignOrderModified = true
    },

    moveUp(index: number) {
      if (index > 0) {
        this.swapCampaigns(index, index - 1)
      }
    },

    moveDown(index: number) {
      if (index < this.editableCampaigns.length - 1) {
        this.swapCampaigns(index, index + 1)
      }
    },

    // ===== Data Transformation Methods =====
    convertToModifiableCampaign(
      campaign: CampaignData,
      index: number
    ): ModifiableCampaignData {
      const startAtParsed = this.parseFirestoreDateTime(campaign.startAt)
      const endAtParsed = this.parseFirestoreDateTime(campaign.endAt)

      return {
        id: campaign.id,
        name: campaign.name,
        scenarioDocId: campaign.scenarioDocId,
        startInterviewDate: startAtParsed.date,
        startInterviewTime: startAtParsed.time,
        endInterviewDate: endAtParsed.date,
        endInterviewTime: endAtParsed.time,
        errorMessage: '',
        index,
      }
    },

    prepareForSaving(campaign: ModifiableCampaignData): CampaignDataChanges {
      const operation = this.determineCampaignOperation(campaign)

      return {
        id: campaign.id ?? null,
        index: campaign.index ?? 0,
        name: campaign.name,
        scenarioDocId: campaign.scenarioDocId,
        startAt: createIsoUtcDateTime(
          campaign.startInterviewDate ?? null,
          campaign.startInterviewTime ?? ''
        ),
        endAt: createIsoUtcDateTime(
          campaign.endInterviewDate ?? null,
          campaign.endInterviewTime ?? ''
        ),
        operation,
      }
    },

    determineCampaignOperation(
      campaign: ModifiableCampaignData
    ): 'create' | 'update' | 'delete' | 'none' {
      if (!campaign.id) return 'create'

      const campaignMods = this.modifications.campaigns[campaign.index ?? 0]
      const hasModifications =
        (campaignMods && Object.values(campaignMods).some((v) => v)) ||
        this.isViewingPeriodFieldModified()
      const isRemovable = this.campaignIdsToDelete.includes(campaign.id)

      if (hasModifications) {
        return isRemovable ? 'delete' : 'update'
      }
      return 'none'
    },

    // ===== Save/Update Methods =====
    async handleEditSubmit() {
      if (this.isEditing) return
      try {
        this.isEditing = true
        const updatedAt = new Date().getTime()

        await this.saveCouponUpdates(updatedAt)
        const campaignUpdates = this.editableCampaigns.map(
          this.prepareForSaving
        )

        await this.saveNewCampaigns(campaignUpdates)
        await this.saveExistingCampaigns(campaignUpdates, updatedAt)
        await this.deleteRemovedCampaigns()
        await this.savePackageChanges(campaignUpdates, updatedAt)

        this.editDialog = false
        this.$router.push('/dashboard/home')
      } catch (error) {
        console.error('Error updating package:', error)
      } finally {
        this.isEditing = false
      }
    },

    async savePackageChanges(
      campaignUpdates: CampaignDataChanges[],
      updatedAt: number
    ) {
      const campaignIds = campaignUpdates
        .filter((campaign) => campaign.operation !== 'delete')
        .map((campaign) => campaign.id)

      const editPackage = httpsCallable(functions, 'editPackage')
      const packagePayload = {
        packageId: this.currentPackage?.id,
        name: this.packageName,
        openAt: createIsoUtcDateTime(
          this.startViewingDate,
          this.startViewingTime
        ),
        closedAt: createIsoUtcDateTime(
          this.endViewingDate,
          this.endViewingTime
        ),
        campaignIds,
        updatedAt,
      }

      await editPackage(packagePayload)
    },

    async saveCouponUpdates(updatedAt: number) {
      if (this.importedCoupons.length === 0) return

      const editCoupons = httpsCallable(functions, 'editCoupons')
      const couponsPayload = {
        packageId: this.currentPackage?.id,
        coupons: this.importedCoupons,
        updatedAt,
      }
      await editCoupons(couponsPayload)
    },

    async saveNewCampaigns(campaignUpdates: CampaignDataChanges[]) {
      const newCampaigns = campaignUpdates.filter(
        (campaign) => campaign.operation === 'create'
      )
      if (newCampaigns.length === 0) return

      // Refetch all package coupons to ensure the latest data is used
      this.allPackageCoupons = await this.fetchPackageCoupons()

      for (const campaign of newCampaigns) {
        await this.createNewCampaignWithCoupons(campaign)
      }
    },

    async saveExistingCampaigns(
      campaignUpdates: CampaignDataChanges[],
      updatedAt: number
    ) {
      const campaignsToUpdate = campaignUpdates.filter(
        (campaign) => campaign.operation === 'update'
      )
      if (campaignsToUpdate.length === 0) return

      const editCampaigns = httpsCallable(functions, 'editCampaigns')
      const updatePayload = {
        packageId: this.currentPackage?.id,
        campaigns: campaignsToUpdate.map((campaign) => ({
          id: campaign.id,
          name: campaign.name,
          scenarioDocId: campaign.scenarioDocId,
          startAt: campaign.startAt,
          endAt: campaign.endAt,
          limits: 1,
          openAt: createIsoUtcDateTime(
            this.startViewingDate,
            this.startViewingTime
          ),
          closedAt: createIsoUtcDateTime(
            this.endViewingDate,
            this.endViewingTime
          ),
        })),
        updatedAt,
      }
      await editCampaigns(updatePayload)
    },

    async deleteRemovedCampaigns() {
      if (this.campaignIdsToDelete.length === 0) return

      const deleteCampaigns = httpsCallable(functions, 'deleteCampaigns')
      const deletePayload = {
        packageId: this.currentPackage?.id,
        campaignIds: this.campaignIdsToDelete,
      }
      await deleteCampaigns(deletePayload)
    },

    // ===== Validation Methods =====
    validateCSVInputFile(file: File) {
      if (!file) {
        this.CSVInputErrorMessage = ''
        return
      }

      Papa.parse(file, {
        complete: (result: any) => {
          const data = result.data
          if (data.length === 0) return

          const codes = new Set(
            this.allPackageCoupons.map((coupon) => coupon.couponCode)
          )
          for (let i = 0; i < data.length; i++) {
            const row = data[i]
            if (row.length === 0) continue

            const [couponCode, password] = row
            if (!couponCode || !password) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.blankCouponError'
              ) as string
              break
            }

            if (row.length !== 2) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.incompleteColumnError'
              ) as string
              break
            }

            if (codes.has(couponCode)) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.duplicatedCouponsError'
              ) as string
              break
            }
            codes.add(couponCode)

            const validPattern = /^[A-Za-z0-9\-_]+$/
            if (
              !validPattern.test(couponCode) ||
              !validPattern.test(password)
            ) {
              this.CSVInputErrorMessage = this.$t(
                'newPackage.validation.CSVFile.halfWithAlphanumbericError'
              ) as string
              break
            }

            this.importedCoupons = data.map((coupon: [string, string]) => ({
              couponCode: coupon[0],
              password: coupon[1],
              isConfirmed: false,
            }))
          }
        },
      })
    },

    validateDateTimeRange(
      startDate: string | null,
      startTime: string,
      endDate: string | null,
      endTime: string
    ): boolean {
      if (!startDate || !startTime || !endDate || !endTime) {
        return false
      }

      const startDateTime = this.createDateFromComponents(startDate, startTime)
      const endDateTime = this.createDateFromComponents(endDate, endTime)
      return endDateTime >= startDateTime
    },

    validateViewingPeriod(campaign: ModifiableCampaignData) {
      if (!campaign) {
        this.editableCampaigns.forEach(this.validateViewingPeriod)
        return
      }

      this.viewingPeriodErrorMessage = ''
      campaign.errorMessage = ''

      // Validate viewing period fields are not empty
      if (
        !this.startViewingDate ||
        !this.startViewingTime ||
        !this.endViewingDate ||
        !this.endViewingTime
      ) {
        this.viewingPeriodErrorMessage = this.$t(
          'editPackage.validation.dateAndTime.required'
        ) as string
        return
      }

      const isViewingPeriodValid = this.validateDateTimeRange(
        this.startViewingDate,
        this.startViewingTime,
        this.endViewingDate,
        this.endViewingTime
      )

      if (!isViewingPeriodValid) {
        this.viewingPeriodErrorMessage = this.$t(
          'newPackage.validation.dateAndTime.endDateError'
        ) as string
        return
      }

      // All interview period fields are required
      if (
        !campaign.startInterviewDate ||
        !campaign.startInterviewTime ||
        !campaign.endInterviewDate ||
        !campaign.endInterviewTime
      ) {
        campaign.errorMessage = this.$t(
          'editPackage.validation.dateAndTime.required'
        ) as string
        return
      }

      const isInterviewPeriodValid = this.validateDateTimeRange(
        campaign.startInterviewDate,
        campaign.startInterviewTime,
        campaign.endInterviewDate,
        campaign.endInterviewTime
      )

      if (!isInterviewPeriodValid) {
        campaign.errorMessage = this.$t(
          'newPackage.validation.dateAndTime.endDateError'
        ) as string
        return
      }

      const startViewingDateTime = this.createDateFromComponents(
        this.startViewingDate,
        this.startViewingTime
      )
      const endViewingDateTime = this.createDateFromComponents(
        this.endViewingDate,
        this.endViewingTime
      )
      const startInterviewDateTime = this.createDateFromComponents(
        campaign.startInterviewDate,
        campaign.startInterviewTime
      )
      const endInterviewDateTime = this.createDateFromComponents(
        campaign.endInterviewDate,
        campaign.endInterviewTime
      )

      if (
        startViewingDateTime > startInterviewDateTime ||
        endViewingDateTime < endInterviewDateTime
      ) {
        campaign.errorMessage = this.$t(
          'newPackage.validation.dateAndTime.interviewPeriodError'
        ) as string
        return
      }
    },

    // ===== Form State Tracking Methods =====
    updateIsFormModified(): boolean {
      if (this.modifications.packageName) return true
      if (this.isViewingPeriodFieldModified()) return true
      if (this.modifications.newCampaigns.size > 0) return true
      if (Object.keys(this.modifications.campaigns).length > 0) return true
      if (this.importedCoupons.length > 0) return true
      if (this.modifications.campaignOrderModified) return true
      if (this.campaignIdsToDelete.length > 0) return true
      return false
    },

    isFieldModified(field: ModifiableField, value: string): boolean {
      return this.initialFormState[field] !== value
    },

    isCampaignFieldModified(index: number, field: CampaignField): boolean {
      if (this.modifications.newCampaigns.has(index)) return true
      return this.modifications.campaigns[index]?.[field] ?? false
    },

    isViewingPeriodFieldModified(): boolean {
      return Object.values(this.modifications.viewingPeriod).includes(true)
    },

    isCampaignInterviewPeriodModified(
      campaign: ModifiableCampaignData
    ): boolean {
      if (!campaign.id) return true

      const index = this.editableCampaigns.findIndex((c) => c === campaign)
      const campaignMods = this.modifications.campaigns[index]
      if (!campaignMods) return false

      return (
        campaignMods['startInterviewDate'] ||
        campaignMods['startInterviewTime'] ||
        campaignMods['endInterviewDate'] ||
        campaignMods['endInterviewTime']
      )
    },

    trackChange(field: ModifiableField, newValue: string) {
      const isModified = this.isFieldModified(field, newValue)
      if (
        field === 'startViewingDate' ||
        field === 'startViewingTime' ||
        field === 'endViewingDate' ||
        field === 'endViewingTime'
      ) {
        this.modifications.viewingPeriod[field] = isModified
        return
      }

      if (field === 'packageName') {
        this.modifications[field] = isModified
      }
    },

    trackCampaignChange(index: number, field: CampaignField, newValue: string) {
      const originalCampaign = this.initialFormState.campaigns[index]
      if (!originalCampaign) return

      if (!this.modifications.campaigns[index]) {
        this.$set(this.modifications.campaigns, index, {})
      }

      const originalValue = originalCampaign[field]
      if (originalValue !== newValue) {
        this.$set(this.modifications.campaigns[index], field, true)
      } else {
        this.$set(this.modifications.campaigns[index], field, false)

        if (
          Object.values(this.modifications.campaigns[index]).every((v) => !v)
        ) {
          this.$delete(this.modifications.campaigns, index)
        }
      }
    },

    // ===== Date/Time Utility Methods =====
    formatDateTime(date: string | null, time = ''): string {
      if (!date) return time as string
      const dateFormat = new Date(date)
      const year = dateFormat.getFullYear()
      const day = String(dateFormat.getDate()).padStart(2, '0')
      const month = String(dateFormat.getMonth() + 1).padStart(2, '0')
      return `${year}/${month}/${day} ${time}`
    },

    formatDateTimeRange(
      startDate: string | null,
      startTime: string,
      endDate: string | null,
      endTime: string
    ): string {
      return `${this.formatDateTime(
        startDate,
        startTime
      )} ~ ${this.formatDateTime(endDate, endTime)}`
    },

    getJstDateTime(date: string): string {
      return moment.tz(date, 'Asia/Tokyo').format('YYYY-MM-DD HH:mm')
    },

    parseFirestoreDateTime(firestoreDateTime: string | number): {
      date: string
      time: string
    } {
      if (typeof firestoreDateTime === 'number') {
        firestoreDateTime = getJstTime(firestoreDateTime, true)
      }
      const jstDateTime = this.getJstDateTime(firestoreDateTime)
      const [date, time] = jstDateTime.split(' ')
      return { date, time }
    },

    createDateFromComponents(date: string | null, time: string): Date {
      if (!date) return new Date(0)
      return moment.tz(`${date} ${time ?? '00:00'}`, 'Asia/Tokyo').toDate()
    },

    // ===== General Utility Methods =====
    chunkArray<T>(array: T[], size: number): T[][] {
      const chunks: T[][] = []
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size))
      }
      return chunks
    },

    downloadCsvSample() {
      const csvContent = [
        ['couponCode0', 'password0'],
        ['couponCode1', 'password1'],
        ['couponCode2', 'password2'],
        ['couponCode3', 'password3'],
        ['couponCode4', 'password4'],
      ]

      const csvString = Papa.unparse(csvContent)
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)

      link.setAttribute('href', url)
      link.setAttribute('download', 'coupon-sample.csv')
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    getScenarioName(scenarioDocId: string | undefined) {
      if (!scenarioDocId) return ''
      return this.availableScenarios.find(
        (scenario) => scenario.scenarioDocId === scenarioDocId
      )?.scenarioName
    },

    // ===== Dialog Management Methods =====
    deletePackageConfirm() {
      this.deleteDialog = true
    },

    async confirmDelete() {
      if (this.isDeleting) return
      this.isDeleting = true
      try {
        const packageId = this.currentPackage?.id
        if (!packageId) {
          throw new Error('Package ID is not defined')
        }
        const deletePackage = httpsCallable(functions, 'deletePackage')
        const result = await deletePackage({ packageId })

        if (!result.data) {
          throw new Error('Failed to delete package')
        }

        this.deleteDialog = false
        this.$router.push('/dashboard/home')
      } catch (error) {
        console.error('Error deleting package:', error)
        this.deleteErrorSnackbarText = this.$t(
          'editPackage.deleteErrorSnackbarText',
          { internalError: `${(error as Error).message ?? ''}` }
        ) as string
        this.deleteErrorSnackbar = true
      } finally {
        this.isDeleting = false
      }
    },

    // ===== Campaign Creation Methods =====
    async createCampaign(
      campaign: CampaignDataChanges
    ): Promise<string | null> {
      const campaignId = v4()

      if (
        !(
          this.currentPackage?.id &&
          this.currentPackage?.customerCode &&
          this.currentPackage?.customerId
        )
      ) {
        throw new Error('Package data is missing required fields')
      }

      const campaignData: Campaign = {
        packageId: this.currentPackage?.id,
        campaignCode: campaignId,
        name: campaign.name ?? '',
        scenarioDocId: campaign.scenarioDocId ?? '',
        category: '',
        customerCode: this.currentPackage.customerCode,
        customerId: this.currentPackage.customerId,
        openAt: createIsoUtcDateTime(
          this.startViewingDate,
          this.startViewingTime
        ),
        closedAt: createIsoUtcDateTime(
          this.endViewingDate,
          this.endViewingTime
        ),
        startAt: campaign.startAt ?? '',
        endAt: campaign.endAt ?? '',
        isDeleted: false,
        isShow: true,
        deletedAt: null,
        isUnlimited: false,
        limits: 1,
        couponCount: 0,
        systemInfo: {
          intella: { version: '' },
          gnowsis: { modelVersion: '', systemVersion: '' },
        },
        updatedAt: createIsoUtcDateTime(),
        isDynamic: false,
      }

      await setDoc(doc(db, 'campaigns', campaignId), campaignData)
      return campaignId
    },

    async createNewCampaignWithCoupons(campaign: CampaignDataChanges) {
      const campaignId = await this.createCampaign(campaign)
      if (!campaignId) return

      campaign.id = campaignId
      await this.copyCouponsToNewCampaign(campaignId, this.allPackageCoupons)
    },

    async copyCouponsToNewCampaign(
      campaignId: string,
      packageCoupons: PackageCoupon[]
    ) {
      for (const coupon of packageCoupons) {
        const payload = {
          couponCode: coupon.couponCode,
          password: coupon.password,
          learnerId: coupon.learnerId ?? null,
          isConfirmed: coupon.learnerId ? true : false,
        }
        await setDoc(
          doc(db, 'campaigns', campaignId, 'coupons', coupon.couponCode),
          payload
        )
        if (!coupon.learnerId) {
          continue
        }
        await this.createTicketForLearner({
          campaignId,
          couponCode: coupon.couponCode,
          password: coupon.password,
          learnerId: coupon.learnerId,
        })
      }
      await updateDoc(doc(db, 'campaigns', campaignId), {
        couponCount: packageCoupons.length,
      })
    },

    async createTicketForLearner({
      campaignId,
      couponCode,
      password,
      learnerId,
    }: {
      campaignId: string
      couponCode: string
      password: string
      learnerId: string
    }) {
      const ticketCollection = collection(db, 'learners', learnerId, 'tickets')
      await addDoc(ticketCollection, {
        campaign: doc(db, 'campaigns', campaignId),
        customerOptions: {
          couponCode: couponCode,
          password: password,
          confirmedAt: createIsoUtcDateTime(),
          punchedAt: null,
          count: 0,
          isAvailable: true,
        },
      })
    },

    // ===== Display Formatting Methods =====
    interviewDateTime(campaign: ModifiableCampaignData): string {
      return this.formatDateTimeRange(
        campaign.startInterviewDate ?? '',
        campaign.startInterviewTime ?? '',
        campaign.endInterviewDate ?? '',
        campaign.endInterviewTime ?? ''
      )
    },
  },
})
</script>
